import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'

import config from '../../../config.json'
import ArrowImg from '../../assets/images/icons/right-arrow.svg'
import Button from '../../components/Button/Button'
import { Arrow, ButtonArrow, ContainerFluid, Paragraph, Title, Wrapper } from './HowItWork.style'

export default function HowItWork({ className, rankingType }) {
  let buttonText, switchLink;

    switch (rankingType) {
      case 'ubezpieczenia-na-zycie':
        {
          buttonText = "Porównaj ubezpieczenia na życie";
          switchLink =  config.CALC_LINK_LIFE;
          break;
        }
      case 'ubezpieczenia-zdrowotne':
        {
          buttonText = "Porównaj ubezpieczenia zdrowotne";
          switchLink = config.CALC_LINK_HEALTH;
          break;
        }
        break
      case 'ubezpieczenia-majatkowe':
        {
          buttonText = "Porównaj ubezpieczenia nieruchomości";
          switchLink = config.CALC_LINK_HH;
          break;
        }

      default:
        {
          buttonText = "Porównaj ubezpieczenia na życie";
          switchLink = config.CALC_LINK;
          break;
        }
      }

  return (
    <ContainerFluid className={className}>
      <Container>
        <Wrapper>
          <Row>
            <Col lg={7} className="justify-content-center">
              <div className="d-flex justify-content-center justify-content-lg-start">
                <StaticImage src="../../assets/images/icons/star-icon.png" loading="lazy" alt="" quality={90} />
              </div>
              <Title>Jak działa nasz ranking? Poznaj zasady.</Title>
              <Paragraph>
                Stworzyliśmy ten ranking w oparciu o opinie Towarzystw Ubezpieczeń z profili na stronach  
                <strong> Google, Facebook, PanoramaFirm.pl, Opinie24.pl, GoWork.pl</strong> i{' '}
                <strong>Absolvent.pl</strong>. Naszym celem jest zebranie maksymalnie wielu opinii, pochodzących ze
                zróżnicowanych źródeł, a dzięki jawnemu algorytmowi ułożenie ich w czytelną dla każdego ocenę.
              </Paragraph>
              <div className="d-flex d-md-inline justify-content-center">
                <Button text={buttonText} externalLink={switchLink} />{' '}
              </div>
              <ButtonArrow to="/jak-to-dziala/">
                Poznaj zasady <Arrow src={ArrowImg} />
              </ButtonArrow>
            </Col>
            <Col lg={5} className="d-none d-lg-block">
              <StaticImage
                src="../../assets/images/home/how-work.png"
                quality={90}
                loading="lazy"
                placeholder="blurred"
                alt=""
              />
            </Col>
          </Row>
        </Wrapper>
      </Container>
    </ContainerFluid>
  )
}
