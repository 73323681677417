import { Link } from 'gatsby'
import styled from 'styled-components'

import { color, layout, layoutMobile } from '../../assets/styles/variables'

export const ContainerFluid = styled.div`
  width: 100%;
  padding-top:${layout.top};
  @media screen and (max-width: 991px) {
    padding-top:${layoutMobile.top};
  }
`

export const Wrapper = styled.div`
  background-color: #053592;
  border-radius: 7px;
  padding: 90px;
  @media screen and (max-width: 991px) {
    padding: 50px;
  }
  @media screen and (max-width: 500px) {
    padding: 30px 15px;
  }
`

export const Title = styled.h2`
  color: #ffffff;
  font-size: 30px;
  font-weight: 700;
  margin-top: 24px;
  margin-bottom: 0.5rem;
  @media screen and (max-width: 991px) {
    text-align: center;
  }
`

export const Paragraph = styled.p`
  color: #ffffff;
  font-size: 18px;
  font-weight: 400;
  margin-top: 24px;
  margin-bottom: 30px;
  strong {
    font-weight: 700;
  }
  @media screen and (max-width: 991px) {
    text-align: center;
  }
`

export const ButtonArrow = styled(Link)`
  text-decoration: none;
  color: #ffffff;
  font-size: 18px;
  padding: 20px 40px 20px 40px;
  font-weight: 600;
  display: inline-flex;
  align-items: center;
  text-align: center;
  background-color: transparent;
  cursor: pointer;
  @media screen and (max-width:1199px){
    display: flex;
    margin: 10px auto 0 auto;
    justify-content: center;
  }
`

export const Arrow = styled.img`
  width: 24px;
  margin-left: 10px;
  display: inline-block;
`


// Styled components for jak-to-dziala page

export const Heading = styled.h2`
font-size: 20px;
    color: ${color.primary};
    font-weight: bold;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    min-height: 55px;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    display: flex;
    line-height: 1.4;
    margin: 0;
    padding-top: 3px;
`