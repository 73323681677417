import PropTypes from 'prop-types'
import React from 'react'
import { Col, Row } from 'react-bootstrap'

import Button from '../Button/Button'
import { ButtonWrapper, CustomContainer, HeadingH1, Paragraph, Small } from './Hero.style'

function Hero({ heading, subheading, paragraphs, img, button, children, reverse }) {
  return (
    <CustomContainer>
      <Row>
        <Col md={6} className="d-flex align-items-center mt-2 mt-lg-0">
          <div style={{width: '100%'}}>
            <HeadingH1>
              {subheading && <Small>{subheading}</Small>}
              {heading && heading}
            </HeadingH1>
            {paragraphs &&
              paragraphs.map((paragraph, i) => {
                return <Paragraph key={i} dangerouslySetInnerHTML={{ __html: paragraph }}></Paragraph>
              })}
            {button ? (
              Array.isArray(button) ? (
                <ButtonWrapper>
                  {button.map((button) => (
                    <Button {...button} padding="15px 35px 15px 35px" margin="0 10px 0 0"/>
                  ))}
                </ButtonWrapper>
              ) : (
                <Button {...button} />
              )
            ) : null}
          </div>
        </Col>
        <Col md={6} className={`order-first  ${reverse ? 'order' : 'order-md-last'} `}>
          {img && <div className="p-2 p-lg-0">{img}</div>}
        </Col>
      </Row>
    </CustomContainer>
  )
}

Hero.propTypes = {
  heading: PropTypes.string,
  subheading: PropTypes.string,
  paragraphs: PropTypes.array,
  image: PropTypes.string,
  button: PropTypes.object,
}

export default Hero
