import { Link } from 'gatsby'
import React from 'react'
import styled from 'styled-components'

import { color, fontFamily } from '../../assets/styles/variables'

const ButtonCta = styled.button`
  color: ${({ secondary }) => (secondary ? color.bgButtonSecondary : '#FFFFFF')};
  background-color: ${({ secondary }) => (secondary ? 'transparent' : color.bgButtonPrimary)};
  margin: ${({margin}) => margin ? margin : "0"};
  border-radius: 7px;
  border: ${({ secondary }) => (secondary ? "2px solid" + color.bgButtonSecondary : 'none')};
  font-size: ${({secondary}) => secondary ? '16px' : '18px'};
  padding: ${({ padding }) => (padding ? padding : '20px 40px 20px 40px')};
  font-weight: 600;
  transition: all 0.2s ease;
  font-family: ${fontFamily.fontSecondary};
  text-align: center;
  max-height: 68px;
  text-decoration: none;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  line-height: ${({secondary}) => secondary ? '1.2' : '1.4'};
  width: ${({width})=> width ? width : null};
  img{
    height: 28px;
    margin-right: 5px
  }
  &:hover {
    background-color: ${({ secondary }) => (secondary ? 'transparent': color.bgButtonPrimaryHover)};
    color: ${({ secondary }) => (secondary ? color.bgButtonSecondaryHover : '#FFFFFF')};
    border: ${({ secondary }) => (secondary ? "2px solid" + color.bgButtonSecondaryHover : 'none')};
  }
  @media screen and (max-width: 1399px) {
   
  }
  @media screen and (min-width: 992px) and (max-width: 1199px){
    max-width: 100%;
    width: 100% !important;
    margin: 5px auto;
  }
  @media screen and (max-width: 991px) {
    padding: 15px 15px 10px;
    width: 100%;
    max-width: 100%;
    text-align: center;
    margin: 5px auto;
    text-align: center;
    font-size: 16px;
    max-height: none;
  }
`

const ConditionalWrappers = ({ condition, condition2, wrapper, wrapper2, children }) => {
  return condition ? wrapper(children) : condition2 ? wrapper2(children) : children
}

function Button({ text, internalLink, externalLink, padding, secondary, width, icon, margin }) {
  return (
    <>
      <ConditionalWrappers
        condition={internalLink}
        condition2={externalLink}
        wrapper={(children) => (
          <Link to={internalLink} style={{ width: width || '100%', zIndex: 1001 }}>
            {children}
          </Link>
        )}
        wrapper2={(children) => (
          <a href={externalLink} style={{ width: width || '100%', zIndex: 1001 }}>
            {children}
          </a>
        )}>
        <ButtonCta padding={padding} secondary={secondary} margin={margin} width={width}>
          {icon && <img src={icon} alt=""/>}{text}
        </ButtonCta>
      </ConditionalWrappers>
    </>
  )
}

export default Button
