import React from 'react'
import styled, { createGlobalStyle } from 'styled-components'

import InfoSvg from '../../assets/images/icons/info.svg'
import { color } from '../../assets/styles/variables'

import StarEmpty from '../../assets/images/icons/star-empty.svg'
import StarFull from '../../assets/images/icons/star-full.svg'
import StarHalf from '../../assets/images/icons/star-half.svg'

export const StarsWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  img {
    width: 20px;
    margin-right: 2px;
  }
  .description {
    color: ${color.grey};
    font-weight: 600;
    font-size: 13px;
    margin-bottom: 0;
    line-height: 1.8;
    margin-top: 4px;
    display: flex;
  }
  .stars {
    margin-top: 5px;
    height: 20px;
    display: flex;
    @media screen and (max-width: 991px) {
      justify-content: ${({ centerMobile }) => (centerMobile ? 'center' : '')};
    }
  }
`
export const TooltipStyle = styled.a`
  position: relative;
  top: 2px;
  margin-left: 4px;
  cursor: pointer;
  img {
    position: relative;
    width: 13px;
    height: 13px;
  }
`
const GlobalStyle = createGlobalStyle`
 [data-tooltip] {
    &:before {
      position: absolute;
      z-index: 2;
      top: 100%;
      left: 50%;
      padding: 1em 1em;
      white-space: normal;
      width: 200px;
      content: attr(data-tooltip);
      color: white;
      font-size: 14px;
      line-height: 1.5em;
      font-weight: 400;
      background-color: ${color.primary};
      opacity: 0;
      transform: translate(-50%, 0);
      border-radius: 4px;
      box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
      transition: transform 0.2s ease-out;
      pointer-events: none;
      transform-origin: top center;
    }
  }
  [data-tooltip] {
    &:after {
      position: absolute;
      z-index: 3;
      display: block;
      top: 100%;
      left: 10px;
      width: 10px;
      height: 10px;
      margin-bottom: -4px;
      content: '';
      background-color: ${color.primary};
      opacity: 0;
      transform: translate(-50%, 0) rotate(45deg);
      transform-origin: top center;
      transition: transform 0.2s ease-out;
      pointer-events: none;
    }
  }
  [data-tooltip] {
    &:hover,
    &:focus {
      &:before {
        opacity: 1;
        transform: translate(-50%, 10px);
        transform-origin: top center;
      }
      &:after {
        opacity: 1;
        transform: translate(-50%, 8px) rotate(45deg);
        transform-origin: top center;
      }
    }
  }
`

export default function Stars({ rating, opinions, centerMobile }) {
  var starArr = []
  const rate = Math.round(rating * 10) / 10
  for (let i = 1; i <= 5; i++) {
    if (i <= rate) {
      starArr.push(StarFull)
    }
    if (i > rate) {
      const fractionalPart = (rate % 1).toFixed(2)
      if (fractionalPart >= 0.5 && fractionalPart < 0.99) starArr.push(StarHalf)
      break
    }
  }
  starArr = Object.assign(new Array(5).fill(StarEmpty), starArr)

  const Tooltip = () => {
    return (
      <TooltipStyle data-tooltip="Średnia opinii na podstawie przeanalizowanych ocen z serwisów Google, Facebook, PanoramaFirm, Opinie24.pl, GoWork.pl, Absolvent.pl">
        <img src={InfoSvg} alt="" />
      </TooltipStyle>
    )
  }

  return (
    <StarsWrapper centerMobile={centerMobile}>
      <GlobalStyle />
      <div className="stars">
        {starArr.map((star, i) => {
          return <img src={star} key={i} alt="" />
        })}
      </div>
      {opinions && (
        <div className="description">
          {`Na podstawie ${opinions} opinii`}
          {Tooltip()}
        </div>
      )}
    </StarsWrapper>
  )
}
