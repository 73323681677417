import styled from 'styled-components'
import { Container } from 'react-bootstrap'
import {
  color,
  fontFamily,
  fontSize,
  fontSizeMobile,
  layout,
  layoutMobile,
} from '../../assets/styles/variables'

export const CustomContainer = styled(Container)`
  padding-top: ${(props)=> props.paddingY ? props.paddingY : layout.top};
  padding-bottom:${(props)=> props.paddingY ? props.paddingY : layout.bottom};
  @media screen and (max-width: 991px) {
    padding-top: ${layoutMobile.top};
    padding-bottom: ${layoutMobile.bottom};
  }
`

export const HeadingH1 = styled.h1`
  font-size: ${fontSize.h1};
  color: ${color.textHeader};
  font-weight: 700;
  font-family: ${fontFamily.fontPrimary};
  line-height: 1.2;
  margin-top: 0;
  margin-bottom: 0.5rem;
  @media screen and (max-width: 991px) {
    font-size: ${fontSizeMobile.h1};
    text-align: center;
  }
`
export const HeadingH2 = styled.h2`
  font-size: ${fontSize.h1};
  color: ${color.textHeader};
  font-weight: 700;
  font-family: ${fontFamily.fontPrimary};
  line-height: 1.2;
  margin-top: 0;
  margin-bottom: 0.5rem;
  @media screen and (max-width: 991px) {
    font-size: ${fontSizeMobile.h1};
    text-align: center;
  }
`

export const Small = styled.span`
  display: block;
  font-size: ${fontSize.h1Small};
  color: ${color.secondary};
  font-family: ${fontFamily.fontPrimary};
  @media screen and (max-width: 991px) {
    font-size: ${fontSizeMobile.h1Small};
    text-align: center;
  }
`

export const Paragraph = styled.p`
  color: ${color.primary};
  font-size: 18px;
  margin-top: 1rem;
  margin-bottom: 1rem;
  strong {
    font-weight: 700;
  }
  @media screen and (max-width: 991px) {
    font-size: ${fontSizeMobile.paragraph};
    text-align: center;
  }
`

export const ButtonWrapper = styled.div`
 display: flex;
 @media screen and (min-width: 1200px){
   width: fit-content;
 }
 @media screen and (max-width: 1199px) {
    flex-direction: column;
    align-items: center;
  }
`
